@import "../../styles/variables";

.sidebar {
  width: 300px;
  height: 100vh;
  overflow-y: hidden;
  background-color: white;

  @media (max-width: 1400px) {
    width: 240px;
  }

  @media (max-width: 1200px) {
    width: 180px;
  }

  @media (max-width: 992px) {
    width: 150px;
  }

  @media (max-width: 768px) {
    width: 80px;
  }

  ul {
    li {
      a {
        color: black;
        border-radius: 10px;

        p {
          @media (max-width: 768px) {
            display: none;
          }
        }
      }
    }
  }

  .logout {
    position: fixed;
    left: 0;
    padding-bottom: 10px;
    width: 240px;
    bottom: 0.5vh;
    @media (max-width: 1535px) {
        bottom: 25px;
    }

    a {
      color: black;
      border-radius: 10px;

      p {
        @media (max-width: 768px) {
          display: none;
        }
      }
    }
  }

  .logo {
    height: 70px;
  }

  .logo > img {
    height: 100%;
  }


  .options {
    position: relative;

    .options_button {
      border: none;
      width: 30px;
      height: 30px;
      font-size: 1rem;
      border-radius: 50%;
      cursor: pointer;
      background: transparent;

      &:hover {
        background: rgb(225, 224, 224);
      }
    }

    div {
      position: absolute;
      padding: 10px;
      right: 0;
      bottom: 100%;
      border-radius: 5px;
      background-color: white;
      box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.473);
    }
  }


  .active_link {
    background-color: $lightgreen;
  }
}