@import "./variables";
//////////////////////////////////////////////////////////////////////////////////
//Global stylings
html {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

form {
  width: 100%;
  display: flex;
  align-items: flex-start;
}

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $shade4 !important;
  * {
    font-family: "Inter", sans-serif;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
}

.layout_main {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

li {
  list-style: none;
}
a {
  text-decoration: none;
  color: inherit;
}

p,
a,
pre {
  line-height: 1.2rem;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  font-size: 0.9rem;
}

h1 {
  font-size: 1.5rem;
  font-weight: bold !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
h2 {
  font-weight: bold !important;
}

h3 {
  font-size: 1rem;
  font-weight: normal;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

////////////////////////////////////////////////////////////////////////////////

//Width
@for $i from 1 to 11 {
  .w_#{$i} {
    width: calc(#{$i} * 10%);
  }
}

@for $i from 1 to 11 {
  //Padding
  .pb_#{$i} {
    padding-bottom: calc(#{$i} * 0.6rem);
  }
  .pt_#{$i} {
    padding-top: calc(#{$i} * 0.6rem);
  }
  .pl_#{$i} {
    padding-left: calc(#{$i} * 0.6rem);
  }
  .pr_#{$i} {
    padding-right: calc(#{$i} * 0.6rem);
  }
  .px_#{$i} {
    padding-right: calc(#{$i} * 0.6rem);
    padding-left: calc(#{$i} * 0.6rem);
  }
  .py_#{$i} {
    padding-top: calc(#{$i} * 0.6rem);
    padding-bottom: calc(#{$i} * 0.6rem);
  }

  //Margin
  .ma {
    margin: auto;
  }
  .mb_#{$i} {
    margin-bottom: calc(#{$i} * 0.6rem);
  }
  .mt_#{$i} {
    margin-top: calc(#{$i} * 0.6rem);
  }
  .ml_#{$i} {
    margin-left: calc(#{$i} * 0.6rem);
  }
  .mr_#{$i} {
    margin-right: calc(#{$i} * 0.6rem);
  }
  .mx_#{$i} {
    margin-right: calc(#{$i} * 0.6rem);
    margin-left: calc(#{$i} * 0.6rem);
  }
  .my_#{$i} {
    margin-top: calc(#{$i} * 0.6rem);
    margin-bottom: calc(#{$i} * 0.6rem);
  }
}

//Borders
.brdr_left {
  border-left: 1px solid $shade3;
}
.brdr_right {
  border-right: 1px solid $shade3;
}
.brdr_bottom {
  border-bottom: 1px solid $shade3;
}
.brdr_top {
  border-top: 1px solid $shade3;
}
.brdr {
  border: 1px solid $shade3;
}

//Text Alinment
.textl {
  text-align: left;
}
.textc {
  text-align: center;
}
.textr {
  text-align: right;
}

//Fontweight
.fwl {
  font-weight: 200;
}
.fwn {
  font-weight: 400;
}
.fwb {
  font-weight: 700;
}

//Custom elements
.glass_button {
  border: none;
  cursor: pointer;
  padding: 0.6rem 1rem;
  background-color: $shade4;
}

.animate_rotation {
  animation: rotation 1.5s linear 0s infinite;
}

.fallback_page {
  width: 100%;
  background-color: $green;
  height: 100vh;
  img {
    width: 200px;
    height: 200px;
  }
}
