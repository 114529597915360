.layout_main {
    display: flex;
    align-items: flex-start;
  }
  
  .main {
    width: calc(100% - 300px);
    display: flex;
    flex-direction: column;
  
    @media (max-width: 1400px) {
        width: calc(100% - 240px);
      }

    @media (max-width: 1200px) {
      width: calc(100% - 180px);
    }
  
    @media (max-width: 992px) {
      width: calc(100% - 150px);
    }
  
    @media (max-width: 768px) {
      width: calc(100% - 80px);
    }
  }
  