@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.animate_opacity-enter {
  opacity: 0;
}

.animate_opacity-enter-active {
  opacity: 1;
  transition: 0.4s ease 0s;
}

.animate_opacity-exit {
  opacity: 1;
}

.animate_opacity-exit-active {
  opacity: 0;
  transition: 0.4s ease 0s;
}
