//Flex
.dflx {
  display: flex;
}
.dflx-c {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.dflx-c-l {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.dflx-r {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.dflx-r-a {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
}
.dflx-r-b {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.align_items_start {
  align-items: flex-start;
}
.align_items_end {
  align-items: flex-end;
}
.align_items_center {
  align-items: center;
}
.justify_content_center {
  justify-content: center;
}
.justify_content_space_around {
  justify-content: space-around;
}
.justify_content_space_between {
  justify-content: space-between;
}
.justify_content_start {
  justify-content: flex-start;
}
.justify_content_end {
  justify-content: flex-end;
}
